import { useEffect } from 'react';
import ReactGA from "react-ga4";

const GoogleAnalytics = () => {

    useEffect(() => {
        ReactGA.initialize("G-C8F31YLXSY");

        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return null;
};

export default GoogleAnalytics;
